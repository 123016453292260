import React, { Fragment, useEffect, useState } from "react";
import qs from "query-string";
import { getFunctions, httpsCallable } from "firebase/functions";
import Loader from "common/components/Loader";
import Swal from "sweetalert2";
import { navigate } from "gatsby";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import firebase from "gatsby-plugin-firebase-v9.0";
import { ThemeProvider } from "@emotion/react";
import { StyledEngineProvider } from "@mui/material";
import { ResetCSS } from "common/assets/css/style";
import Navbar from "containers/Landing/Navbar";
import { DrawerProvider } from "common/contexts/DrawerContext";
import SEO from "components/seo";
import Sticky from "react-stickynode";
import Footer from "containers/Landing/Footer";
import {
  GlobalStyle,
  ContentWrapper,
} from "containers/Landing/sassModern.style";
import { theme } from "../theme";

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.tz.setDefault("America/Santiago");

export default (props) => {
  const [loading, setLoading] = useState(false);
  const [mercadoPagoParams, setMercadoPagoParams] = useState<
    MercadoPagoCallbackParams
  >();
  const [appointmentDetails, setAppointmentDetails] = useState();

  useEffect(() => {
    if (typeof window !== `undefined`) {
      const queryString = props.location.search;
      const urlParams = qs.parse(queryString) as MercadoPagoCallbackParams;
      if (urlParams) {
        setMercadoPagoParams(urlParams);
      }
    }
  }, []);

  useEffect(() => {
    if (mercadoPagoParams)
      if (mercadoPagoParams.status === "approved")
        (async () => {
          setLoading(true);
          const functions = getFunctions(firebase, "southamerica-east1");
          const getAppointmentDetails = httpsCallable<
            { appointment_id: string },
            unknown
          >(functions, "getAppointmentDetails");
          const appointment = await getAppointmentDetails({
            appointment_id: mercadoPagoParams.external_reference,
          });
          setAppointmentDetails(appointment.data);
          setLoading(false);
        })().catch(console.error);
      else if (mercadoPagoParams.status === "pending") {
        Swal.fire({
          title: "Pago procesando",
          icon: "question",
          text: "Su pago se encuentra siendo procesado por mercadopago.",
        }).then(() => navigate("/"));
      } else {
        Swal.fire({
          title: "Error al procesar su pago",
          icon: "error",
          text: "Hubo un error al procesar su pago",
        }).then(() => navigate("/"));
      }
  }, [mercadoPagoParams]);

  useEffect(() => {
    if (appointmentDetails) {
      Swal.fire({
        title: "Cita confirmada",
        icon: "success",
        html: `
        <p><b>Código de pago exitoso:</b> ${mercadoPagoParams.payment_id}</p>
        <p><b>ID de cita: </b>${mercadoPagoParams.external_reference}</p>
        <p><b>Fecha y hora: </b>${dayjs
          .tz(appointmentDetails.appointment.datetime, "America/Santiago")
          .format("DD-MM-YYYY HH:mm")}</p>
        <p><b>Servicio: </b>${appointmentDetails.product.description}</p>
        <p><b>Dirección: </b>${appointmentDetails.appointment.address}</p>
        <p><b>Comuna: </b>${appointmentDetails.appointment.comuna}</p>
        <p>Se ha enviado un correo de confirmación. En preparación a la cita, por favor siga las siguientes indicaciones:</p>
        <b>${appointmentDetails.product.instruction}</b>
        `,
      }).then(() => {
        navigate("/");
      });
    }
  }, [appointmentDetails]);
  return (
    <>
      {loading && <Loader />}
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <Fragment>
            <SEO title="MiDiagnóstico" />

            <ResetCSS />
            <GlobalStyle />

            <ContentWrapper>
              <Sticky top={0} innerZ={9999} activeClass="sticky-nav-active">
                <DrawerProvider>
                  <Navbar />
                </DrawerProvider>
              </Sticky>

              <Footer />
            </ContentWrapper>
          </Fragment>
        </ThemeProvider>
      </StyledEngineProvider>
    </>
  );
};

type MercadoPagoCallbackParams = {
  payment_id: string;
  status: "approved" | "pending";
  external_reference: string;
  merchant_order_id: string;
};
